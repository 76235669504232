import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Scopes",
  "path": "/docs/scopes"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Scopes`}</h1>
    <h2>{`About scopes and permissions for enablehr`}</h2>
    <p>{`To use or manage any resource in enablehr, you must use a token with
the appropriate scope, and your user account must have appropriate
permissions for that resource`}</p>
    <p>{`For example:`}</p>
    <p>{`To list employees in your account , your token must
have the read:employees scope, and your user account must have read
permissions for the account`}</p>
    <h3>{`These are the scopes currently available to the client.`}</h3>
    <br />
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Scope`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`read:accounts:referencedata`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`List and retrieve reference data of various field values. Eg: Genders, Honorifics etc.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`manage:accounts:referencedata`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Add, Update and Delete reference data of various fields.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`read:employees`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`List all the employees in an account`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`manage:employees`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Update employee data in an account`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`read:candidates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`List all the candidates in an account`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`manage:candidates`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Update employee data in an account`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`read:employees:payroll`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`List and retrieve Leave, payroll & tax data of employees (AU & NZ)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`read:clear-tax-details`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Retrieve unmasked TFN number for an employee in Australia`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`manage:employees:payroll`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Update Leave, payroll & tax data of employees (AU & NZ)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`read:accounts:training`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`List and retrieve training & qualifications setup data for an account`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`read:employees:training`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`List and retrieve training & qualifications data of employees`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`manage:employees:training`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Update training & qualifications data of employees`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`read:users`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`List & retrieve enableHR and ESS users for an account`}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <p>{`Note: Your OAuth App can request the scopes in the initial
redirection. You can specify multiple scopes by separating them with a
space using %20:`}</p>
    <p><a parentName="p" {...{
        "href": "https://login.enablehr.com/oauth/authorize?client_id=...&scope=manage:employees%20manage:employees:payroll"
      }}>{`https://login.enablehr.com/oauth/authorize?client_id=...&scope=manage:employees%20manage:employees:payroll`}</a></p>
    <h3>{`Requested scopes and granted scopes`}</h3>
    <p>{`The scope attribute lists scopes attached to the token that were
granted by the user. Normally, these scopes will be identical to what
you requested. However, users can edit their scopes, effectively
granting your application less access than you originally
requested. You should be aware of this possibility and adjust
your application's behavior accordingly.`}</p>
    <p>{`It's important to handle error cases where a user chooses to grant you
less access than you originally requested. For example, applications
can warn or otherwise communicate with their users that they will see
reduced functionality or be unable to perform some actions.`}</p>
    <p>{`Also, applications can always send users back through the flow again
to get additional permission, but don’t forget that users can always
say no.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      